/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"P O R T F O L I O"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1nq99vz --style2 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006__s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006__s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006__s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006__s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006__s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006__s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006__s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006__s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--48 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"P O R T F O L I O"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/fed1d697890a419d97374467666ba9a8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/fed1d697890a419d97374467666ba9a8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/fed1d697890a419d97374467666ba9a8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/fed1d697890a419d97374467666ba9a8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13459/fed1d697890a419d97374467666ba9a8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/a76d2498466b41718ba12bf3e2b60afc_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/a76d2498466b41718ba12bf3e2b60afc_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/a76d2498466b41718ba12bf3e2b60afc_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/a76d2498466b41718ba12bf3e2b60afc_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/a76d2498466b41718ba12bf3e2b60afc_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13459/a76d2498466b41718ba12bf3e2b60afc_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/dc8f66fdd28c455a82525cf83ce60edf_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/dc8f66fdd28c455a82525cf83ce60edf_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/dc8f66fdd28c455a82525cf83ce60edf_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/dc8f66fdd28c455a82525cf83ce60edf_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/dc8f66fdd28c455a82525cf83ce60edf_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/db4fd9db3ca54a00b96450a4719ed3b9_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/db4fd9db3ca54a00b96450a4719ed3b9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/db4fd9db3ca54a00b96450a4719ed3b9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/db4fd9db3ca54a00b96450a4719ed3b9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13459/db4fd9db3ca54a00b96450a4719ed3b9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13459/db4fd9db3ca54a00b96450a4719ed3b9_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/106677048a724d208e3992698684b2d1_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/106677048a724d208e3992698684b2d1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/106677048a724d208e3992698684b2d1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/106677048a724d208e3992698684b2d1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13459/106677048a724d208e3992698684b2d1_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/8a6fd2a701134fd385a276c32d7ba2f7_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/8a6fd2a701134fd385a276c32d7ba2f7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/8a6fd2a701134fd385a276c32d7ba2f7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/8a6fd2a701134fd385a276c32d7ba2f7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13459/8a6fd2a701134fd385a276c32d7ba2f7_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/2aae2abf9ae04cc9b4883d23c857bf45_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/2aae2abf9ae04cc9b4883d23c857bf45_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/2aae2abf9ae04cc9b4883d23c857bf45_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/2aae2abf9ae04cc9b4883d23c857bf45_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/2aae2abf9ae04cc9b4883d23c857bf45_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13459/2aae2abf9ae04cc9b4883d23c857bf45_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/49a1c3a6c9574457a4998be3eedea4bd_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/49a1c3a6c9574457a4998be3eedea4bd_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/49a1c3a6c9574457a4998be3eedea4bd_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/49a1c3a6c9574457a4998be3eedea4bd_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/49a1c3a6c9574457a4998be3eedea4bd_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13459/49a1c3a6c9574457a4998be3eedea4bd_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/1f1796e08bb148cc9d4f16257d75732c_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/1f1796e08bb148cc9d4f16257d75732c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/1f1796e08bb148cc9d4f16257d75732c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/1f1796e08bb148cc9d4f16257d75732c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/c4045a7c09cd4df1bd9b1fe237a1871d_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/c4045a7c09cd4df1bd9b1fe237a1871d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/c4045a7c09cd4df1bd9b1fe237a1871d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/c4045a7c09cd4df1bd9b1fe237a1871d_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/e4eec82b3b3d479aaa6a78e37d3abe49_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/e4eec82b3b3d479aaa6a78e37d3abe49_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/e4eec82b3b3d479aaa6a78e37d3abe49_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/e4eec82b3b3d479aaa6a78e37d3abe49_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/d2f3235a691241469d180848a9f6483b_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/d2f3235a691241469d180848a9f6483b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/d2f3235a691241469d180848a9f6483b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/d2f3235a691241469d180848a9f6483b_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/a631072d05ac48d09b27176ab7228dce_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/a631072d05ac48d09b27176ab7228dce_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/a631072d05ac48d09b27176ab7228dce_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/a631072d05ac48d09b27176ab7228dce_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13459/a631072d05ac48d09b27176ab7228dce_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/c70e80eb52644f3a977318a38bdaf982_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/c70e80eb52644f3a977318a38bdaf982_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/c70e80eb52644f3a977318a38bdaf982_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/c70e80eb52644f3a977318a38bdaf982_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/767508616b7f43ae8f42b5a3befa8d10_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/767508616b7f43ae8f42b5a3befa8d10_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/767508616b7f43ae8f42b5a3befa8d10_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/767508616b7f43ae8f42b5a3befa8d10_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/b67527fccab6463db984af2e5e713cb4_s=660x_.PNG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/b67527fccab6463db984af2e5e713cb4_s=350x_.PNG 350w, https://cdn.swbpg.com/t/13459/b67527fccab6463db984af2e5e713cb4_s=660x_.PNG 660w, https://cdn.swbpg.com/t/13459/b67527fccab6463db984af2e5e713cb4_s=860x_.PNG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/66cc0aff4be44fbdb5b5df0131805c65_s=660x_.PNG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/66cc0aff4be44fbdb5b5df0131805c65_s=350x_.PNG 350w, https://cdn.swbpg.com/t/13459/66cc0aff4be44fbdb5b5df0131805c65_s=660x_.PNG 660w, https://cdn.swbpg.com/t/13459/66cc0aff4be44fbdb5b5df0131805c65_s=860x_.PNG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/f7678930f44040cb9000ea5b31cfb051_s=660x_.PNG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/f7678930f44040cb9000ea5b31cfb051_s=350x_.PNG 350w, https://cdn.swbpg.com/t/13459/f7678930f44040cb9000ea5b31cfb051_s=660x_.PNG 660w, https://cdn.swbpg.com/t/13459/f7678930f44040cb9000ea5b31cfb051_s=860x_.PNG 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/83e2df7dcf9d4f2890e8f578e4e9f221_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/83e2df7dcf9d4f2890e8f578e4e9f221_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/83e2df7dcf9d4f2890e8f578e4e9f221_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/83e2df7dcf9d4f2890e8f578e4e9f221_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/724bac2c354b44ac8684913c76db3e03_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/724bac2c354b44ac8684913c76db3e03_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/724bac2c354b44ac8684913c76db3e03_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/9e25de1428214d2abcad9f61d2a21d30_e=190x0x1341x1006_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/d7bb9cda84634cffa0b650a64a8b7d0a_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/d7bb9cda84634cffa0b650a64a8b7d0a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/d7bb9cda84634cffa0b650a64a8b7d0a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/d7bb9cda84634cffa0b650a64a8b7d0a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13459/d7bb9cda84634cffa0b650a64a8b7d0a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13459/d7bb9cda84634cffa0b650a64a8b7d0a_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/ba3b1139294e4681b6a807647e8f2946_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/ba3b1139294e4681b6a807647e8f2946_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/ba3b1139294e4681b6a807647e8f2946_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/ba3b1139294e4681b6a807647e8f2946_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/ba3b1139294e4681b6a807647e8f2946_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13459/ba3b1139294e4681b6a807647e8f2946_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/7b3d3fcc8c694dcf95c5539a896bde67_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/7b3d3fcc8c694dcf95c5539a896bde67_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/7b3d3fcc8c694dcf95c5539a896bde67_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/7b3d3fcc8c694dcf95c5539a896bde67_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/7b3d3fcc8c694dcf95c5539a896bde67_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13459/7b3d3fcc8c694dcf95c5539a896bde67_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/1287e7b06740491db29d9311068d890f_s=660x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/1287e7b06740491db29d9311068d890f_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13459/1287e7b06740491db29d9311068d890f_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13459/1287e7b06740491db29d9311068d890f_s=860x_.JPG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/dd65f7066bdb47f5a0b938e0fb4b8152_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/dd65f7066bdb47f5a0b938e0fb4b8152_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13459/dd65f7066bdb47f5a0b938e0fb4b8152_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13459/dd65f7066bdb47f5a0b938e0fb4b8152_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13459/dd65f7066bdb47f5a0b938e0fb4b8152_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13459/dd65f7066bdb47f5a0b938e0fb4b8152_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/6a6cd11a47694fb0a10f129bd819971e_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/6a6cd11a47694fb0a10f129bd819971e_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13459/6a6cd11a47694fb0a10f129bd819971e_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13459/6a6cd11a47694fb0a10f129bd819971e_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13459/6a6cd11a47694fb0a10f129bd819971e_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13459/6a6cd11a47694fb0a10f129bd819971e_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/39a61fb47de944d8b86f43420a1cc7e2_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/39a61fb47de944d8b86f43420a1cc7e2_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/39a61fb47de944d8b86f43420a1cc7e2_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/39a61fb47de944d8b86f43420a1cc7e2_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/0b98c9ef83e74a1ab14c73573531f757_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/0b98c9ef83e74a1ab14c73573531f757_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/0b98c9ef83e74a1ab14c73573531f757_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/0b98c9ef83e74a1ab14c73573531f757_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/a34d255e1bb44e3cabdb7f8e0e692b5c_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/a34d255e1bb44e3cabdb7f8e0e692b5c_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/a34d255e1bb44e3cabdb7f8e0e692b5c_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/a34d255e1bb44e3cabdb7f8e0e692b5c_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/250455a96ba242ecb7ed68d766f858f8_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/250455a96ba242ecb7ed68d766f858f8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/250455a96ba242ecb7ed68d766f858f8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/250455a96ba242ecb7ed68d766f858f8_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/c876898633b1483b90f4eaba37936737_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/c876898633b1483b90f4eaba37936737_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/c876898633b1483b90f4eaba37936737_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/c876898633b1483b90f4eaba37936737_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/a20778df0a5d4d6f8b8602961540215b_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/a20778df0a5d4d6f8b8602961540215b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/a20778df0a5d4d6f8b8602961540215b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/a20778df0a5d4d6f8b8602961540215b_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/3f3cf60b375049279cac1b104c824c97_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/3f3cf60b375049279cac1b104c824c97_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/3f3cf60b375049279cac1b104c824c97_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/3f3cf60b375049279cac1b104c824c97_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/3221e9af229943259f51a88106c61c85_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/3221e9af229943259f51a88106c61c85_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/3221e9af229943259f51a88106c61c85_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/3221e9af229943259f51a88106c61c85_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/d95c1ff8d3ea4d16ad0a59e8bfcb86a8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/d95c1ff8d3ea4d16ad0a59e8bfcb86a8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/d95c1ff8d3ea4d16ad0a59e8bfcb86a8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/d95c1ff8d3ea4d16ad0a59e8bfcb86a8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13459/d95c1ff8d3ea4d16ad0a59e8bfcb86a8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/8eb4b60e408a40bc9aadf0cfae5cb998_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/8eb4b60e408a40bc9aadf0cfae5cb998_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/8eb4b60e408a40bc9aadf0cfae5cb998_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/8eb4b60e408a40bc9aadf0cfae5cb998_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/2d73b1e0dfdd40b2b5dbf75853c1c1f1_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/2d73b1e0dfdd40b2b5dbf75853c1c1f1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/2d73b1e0dfdd40b2b5dbf75853c1c1f1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/2d73b1e0dfdd40b2b5dbf75853c1c1f1_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/91a5176706ea4781bab38fef8d31e2c2_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/91a5176706ea4781bab38fef8d31e2c2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/91a5176706ea4781bab38fef8d31e2c2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/91a5176706ea4781bab38fef8d31e2c2_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/f1b3f3d33bdd4585bd652429fb88322f_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/f1b3f3d33bdd4585bd652429fb88322f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/f1b3f3d33bdd4585bd652429fb88322f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/f1b3f3d33bdd4585bd652429fb88322f_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/1bc6613859124e2a96646233f8217b2c_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/1bc6613859124e2a96646233f8217b2c_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/1bc6613859124e2a96646233f8217b2c_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/1bc6613859124e2a96646233f8217b2c_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/fdc0b1bfdb054b30a5e181512c11a8d3_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/fdc0b1bfdb054b30a5e181512c11a8d3_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/fdc0b1bfdb054b30a5e181512c11a8d3_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/fdc0b1bfdb054b30a5e181512c11a8d3_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/e4c76301274547ebb77d3d18c47611cc_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/e4c76301274547ebb77d3d18c47611cc_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/e4c76301274547ebb77d3d18c47611cc_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/e4c76301274547ebb77d3d18c47611cc_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13459/e4c76301274547ebb77d3d18c47611cc_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/dc2a174ecc564c4c84a4a37b98857dd5_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/dc2a174ecc564c4c84a4a37b98857dd5_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/dc2a174ecc564c4c84a4a37b98857dd5_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/dc2a174ecc564c4c84a4a37b98857dd5_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13459/dc2a174ecc564c4c84a4a37b98857dd5_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/ce71df16336b4df99f0cbdd760839e00_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/ce71df16336b4df99f0cbdd760839e00_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/ce71df16336b4df99f0cbdd760839e00_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/ce71df16336b4df99f0cbdd760839e00_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13459/ce71df16336b4df99f0cbdd760839e00_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/9e2f8691f7104f6ba36e6152bdf50a9d_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/9e2f8691f7104f6ba36e6152bdf50a9d_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/9e2f8691f7104f6ba36e6152bdf50a9d_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/9e2f8691f7104f6ba36e6152bdf50a9d_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/9e2f8691f7104f6ba36e6152bdf50a9d_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/9f22b314b22a4783a71df3a6c67215eb_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/9f22b314b22a4783a71df3a6c67215eb_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13459/9f22b314b22a4783a71df3a6c67215eb_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13459/9f22b314b22a4783a71df3a6c67215eb_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/2cd9d02e0b1845f598012d4255c1fb7e_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/2cd9d02e0b1845f598012d4255c1fb7e_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/2cd9d02e0b1845f598012d4255c1fb7e_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/2cd9d02e0b1845f598012d4255c1fb7e_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/2cd9d02e0b1845f598012d4255c1fb7e_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box fs--14" content={"I N G . &nbsp;A R C H . &nbsp;J A N A &nbsp;L O R E N C O V Á"}>
              </Title>

              <Text className="text-box fs--13" content={"Hlaváčkova 3205, 440 01 Louny\n<br>+420 725 649 523\n<br>lorencovajana@gmail.com<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}